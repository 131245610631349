import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { FILE_TYPES_ICONS } from './states';
import CustomTableElement from '../pages/Permisos/components/CustomTableElement';

export const ApplicationHeaders: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
        align: 'left',
        resizable: false
    },
    {
        field: 'nombre',
        headerName: 'NOMBRE',
        minWidth: 150,
        flex: 1
    },
    {
        field: 'identificador',
        headerName: 'IDENTIFICADOR',
        width: 250,
        align: 'left',
        sortable: false,
        flex: 1
    },
    {
        field: 'apiKey',
        headerName: 'API KEY',
        width: 250,
        align: 'left',
        sortable: false,
        flex: 1
    },
    {
        field: 'cidi',
        headerName: 'CIDI',
        width: 110,
        align: 'left',
        sortable: false,
        resizable: false
    },
    {
        field: 'vedi',
        headerName: 'VEDI',
        width: 110,
        align: 'left',
        sortable: false,
        resizable: false
    },
    {
        field: 'descripcion',
        headerName: 'DESCRIPCION',
        flex: 1,
        minWidth: 100,
        sortable: false
    }
];

export const DocumentHeaders: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
        align: 'left',
        resizable: false
    },
    {
        field: 'nombre',
        headerName: 'NOMBRE',
        minWidth: 250,
        resizable: false,
        flex: 1
    },
    {
        field: 'tiposArchivo',
        headerName: 'TIPOS DE ARCHIVOS',
        width: 400,
        valueGetter: (_: any, row: any) => {
            return row?.tiposArchivo?.map((ft: any) => ft?.extension?.toUpperCase()).join(', ');
        }
    },
    {
        field: 'descripcion',
        headerName: 'DESCRIPCIÓN',
        width: 400,
        resizable: false
    }
];

export const FileTypesHeaders: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
        align: 'left',
        resizable: false
    },
    {
        field: 'icono',
        headerName: 'ICONO',
        width: 90,
        align: 'center',
        headerAlign: 'center',
        resizable: false,
        cellClassName: 'flex-center',
        renderCell: (params: GridRenderCellParams<any, React.ReactNode>) => {
            let valueType = params?.row?.tipo;
            return FILE_TYPES_ICONS[valueType];
        },
        sortable: false
    },

    {
        field: 'nombre',
        headerName: 'NOMBRE',
        minWidth: 300,
        flex: 1,
        resizable: false
    },
    {
        field: 'tipo',
        headerName: 'TIPO',
        width: 250,
        resizable: false
    },
    {
        field: 'extension',
        headerName: 'EXTENSIÓN',
        width: 150,
        align: 'center',
        headerAlign: 'center',
        resizable: false,
        sortable: false
    }
];

export const PermissionHeaders = [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
        align: 'left',
        resizable: false
    },
    {
        field: 'aplicacion',
        headerName: 'APLICACIÓN',
        minWidth: 250,
        valueGetter: (_: any, row: any) => {
            return row?.aplicacion?.nombre || '';
        },
        flex: 1
    },
    {
        field: 'tipoDocumento',
        headerName: 'TIPO DE DOCUMENTO',
        valueGetter: (_: any, row: any) => {
            return row?.tipoDocumento?.nombre || '';
        },
        width: 250
    },
    {
        field: 'cargar',
        headerName: 'CARGAR',
        width: 160,
        align: 'center',
        headerAlign: 'center',
        cellClassName: 'flex-center',
        renderCell: (params: GridRenderCellParams) => CustomTableElement(params?.row?.cargar),
        resizable: false
    },
    {
        field: 'descargar',
        headerName: 'DESCARGAR',
        width: 160,
        align: 'center',
        headerAlign: 'center',
        cellClassName: 'flex-center',
        renderCell: (params: GridRenderCellParams) => CustomTableElement(params?.row?.descargar),
        resizable: false
    },
    {
        field: 'eliminar',
        headerName: 'ELIMINAR',
        width: 160,
        align: 'center',
        headerAlign: 'center',
        cellClassName: 'flex-center',
        renderCell: (params: GridRenderCellParams) => CustomTableElement(params?.row?.eliminar),
        resizable: false
    }
];
